/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { graphql } from 'gatsby'

import { SEO } from '../components'
import { LocaleContext } from '../components/Layout'
import bubbles from '../../content/images/bubbles.svg'
import HeroWithImage from '../components/shared/HeroWithImage'
import ListingNews from '../components/News/ListingNews'
import * as S from '../components/News/styled'

const News = ({ location, data: { content, categories, posts }, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const {
    data: {
      hero_title: heroTitle,
      hero_image: { url: heroUrlImage },
      section_title: sectionTitle,
      description: { html },
    },
  } = content

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} banner={heroUrlImage} title={i18n.seo.news.title} />
      <HeroWithImage src={heroUrlImage} title={heroTitle} />
      <S.Wrapper>
        <S.InnerWrapper>
          <S.Row>
            <S.Col>
              <S.Title>{sectionTitle}</S.Title>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Bubbles src={bubbles} />
            <S.TextCol>
              <S.Text dangerouslySetInnerHTML={{ __html: html }} />
            </S.TextCol>
          </S.Row>
          <S.NavCategories id="nav">
            <S.Link activeClassName="NavLink__currentPath" partiallyActive to="/news/#nav">
              {i18n.allCategories}
            </S.Link>
            {categories.edges.map(cat => (
              <S.Link key={cat.node.data.name} to={`/news/${kebabCase(cat.node.uid)}#nav`}>
                {cat.node.data.name}
              </S.Link>
            ))}
          </S.NavCategories>
          <ListingNews posts={posts.edges} />
        </S.InnerWrapper>
      </S.Wrapper>
    </>
  )
}

export default News

News.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query NewsOverview($locale: String!) {
    content: prismicNewsPage(lang: { eq: $locale }) {
      data {
        hero_title
        hero_image {
          url
        }
        section_title
        description {
          html
        }
      }
    }
    posts: allPrismicPost(filter: { lang: { eq: $locale } }, sort: { fields: [data___date], order: DESC }) {
      totalCount
      edges {
        node {
          uid
          data {
            image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            title {
              text
            }
            description
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    categories: allPrismicCategory(filter: { lang: { eq: $locale } }, sort: { fields: [data___name], order: ASC }) {
      totalCount
      edges {
        node {
          uid
          data {
            name
          }
        }
      }
    }
  }
`
